





















import Vue from 'vue';
import Component from 'vue-class-component';
import Unwatcher from '@/utils/unwatcher';

@Component({
  components: {},
})
export default class SessionEndedModalComponent extends Vue {
  unwatcher: Unwatcher = new Unwatcher();
  isLoggedIn: boolean = false;

  public created() {
    this.$store.watch(
      (state: any, getters: any) => state.user.isLoggedIn,
      (newValue: boolean, _oldValue: boolean) => {
        this.isLoggedIn = newValue;
      },
    );
  }

  public beforeDestroy() {
    this.unwatcher.unwatchAll();
  }

  public data() {
    return {
      isLoggedIn: this.$store.state.user.isLoggedIn,
    };
  }
}
