



















































import Vue from 'vue';
import Component from 'vue-class-component';
import SessionEndedModalComponent from '@/components/SessionEndedModalComponent.vue';
import Unwatcher from '@/utils/unwatcher';
import { flavor, flavorData } from '@/flavor/index';
import { Flavor } from '@/flavor/types';
import I18n from '../i18n';
import { Survey } from '../store/modules/survey/types';

interface MenuItem {
  icon: string;
  label: string;
  path: string;
  accesskey: number|null;
  requireSurvey: boolean;
}

@Component({
  components: {
    SessionEndedModalComponent,
  },
})
export default class Menu extends Vue {
  unwatcher: Unwatcher = new Unwatcher();
  hasSurvey: boolean = false;
  mainMenuItems: MenuItem[] = [];
  supportMenuItems: MenuItem[] = [];
  flavor: Flavor = flavor;
  appName: string = flavorData.name;

  public created() {
    this.unwatcher.push(this.$store.watch(
      (state: any, getters: any) => getters['survey/getSelected'],
      (newValue: Survey|null, _oldValue: Survey|null) => {
        this.hasSurvey = newValue !== null;
      },
    ));
  }

  public beforeDestory() {
    this.unwatcher.unwatchAll();
  }

  public data() {
    const surveyMenuItem = {
      icon: 'layers',
      label: I18n.t('menu.survey'),
      path: '/survey',
      accesskey: 1,
      requireSurvey: false,
    } as MenuItem;
    const samplesMenuItem = {
      icon: 'account-multiple',
      label: I18n.t(`${flavor}.menu.sample`),
      path: '/sample',
      accesskey: null,
      requireSurvey: true,
    } as MenuItem;
    const weightingMenuItem = {
      icon: 'scale-balance',
      label: I18n.t('menu.weighting'),
      path: '/weighting',
      accesskey: null,
      requireSurvey: true,
    } as MenuItem;
    const analysisMenuItem = {
      icon: 'chart-line',
      label: I18n.t(`${flavor}.menu.analysis`),
      path: '/analysis',
      accesskey: null,
      requireSurvey: true,
    } as MenuItem;
    // If the flavor is activeviewer we skip the weighting menu item
    const mainMenuItems: MenuItem[] = (flavor === Flavor.travelViewer)
      ? [surveyMenuItem, samplesMenuItem, weightingMenuItem, analysisMenuItem]
      : [surveyMenuItem, samplesMenuItem, analysisMenuItem];

    return {
      hasSurvey: this.$store.getters['survey/getSelected'] !== null,
      mainMenuItems,
      supportMenuItems: [
        {
          icon: 'lifebuoy',
          label: I18n.t('menu.support'),
          path: '/support',
          accesskey: 9,
          requireSurvey: false,
        },
      ] as MenuItem[],
    };
  }
}
