import Log from '@/utils/log';

/**
 * Push responses from $store.watch() to instance of this class
 * and then in component beforeDestroy() call unwatchAll() to untwatch
 * all watchers
 */
export default class {
  private list : Array<any> = [];

  push(handle : any) {
    this.list.push(handle);
  }

  unwatchAll() {
    Log.log('Unwatch watch()');
    this.list.forEach((handle) => {
      handle();
    });
  }
}
